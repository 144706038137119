<template>
    <div class="relative rounded-md shadow-sm">
        <div v-if="showIcon" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd" />
            </svg>
        </div>
        <input autocomplete="off" data-lpignore="true"  :disabled="disabled" :value="value" :placeholder="placeholder" @input="handleInput" :type="type" name="email"
            id="email"
            :class="[
                'block w-full rounded-md border-solid focus:ring-blue-500 focus:border-blue-500 sm:text-sm border-gray-300 placeholder:text-gray-300 placeholder:font-normal placeholder:text-sm focus:ring-2 focus:ring-inset sm:leading-6', { 'pl-10': showIcon }, 'py-' + py, 'h-' + h]" />
    </div>
</template>
  
<script setup>

const props = defineProps({
    showIcon: {
        type: Boolean,
        default: false
    },
    value: {
        type: String || Number,
        required: true
    },
    placeholder: {
        type: String,
        default: "Search..."
    },
    py: {
        type: String,
        default: '1.5'
    },
    h: {
        type: String,
        default: 'fit'
    },
    disabled: {
        type: Boolean,
        default: false
    },
    type:{
        type: String,
        default: "email"
    }
});

const emit = defineEmits(['input']);

const handleInput = (e) => {
    emit('input', e);
}
</script>
  